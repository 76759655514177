import { defineComponent } from '@nuxtjs/composition-api';
import { SfSelect, SfButton, } from '@storefront-ui/vue';
import SvgImage from '~/components/General/SvgImage.vue';
import { useUiHelpers, useUiState } from '~/composables';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
export default defineComponent({
    components: {
        SkeletonLoader,
        SvgImage,
        SfSelect,
        SfButton,
    },
    props: {
        sortBy: {
            type: Object,
            required: true,
        },
        pagination: {
            type: Object,
            required: true,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    setup(_, { emit }) {
        const { toggleFilterSidebar, changeToCategoryListView, changeToCategoryGridView, isCategoryGridView, } = useUiState();
        const uiHelpers = useUiHelpers();
        const doChangeSorting = (sort) => {
            uiHelpers.changeSorting(sort, false);
            emit('reloadProducts');
        };
        return {
            toggleFilterSidebar,
            doChangeSorting,
            ...uiHelpers,
            changeToCategoryListView,
            changeToCategoryGridView,
            isCategoryGridView,
        };
    },
});
