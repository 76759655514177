export const getMetaInfo = (page, isNoIndex = false) => {
    if (!page) {
        return null;
    }
    const seoTags = {
        meta: [],
    };
    if ((page === null || page === void 0 ? void 0 : page.meta_title) || (page === null || page === void 0 ? void 0 : page.title) || (page === null || page === void 0 ? void 0 : page.name)) {
        seoTags.title = (page === null || page === void 0 ? void 0 : page.meta_title) || (page === null || page === void 0 ? void 0 : page.title) || (page === null || page === void 0 ? void 0 : page.name);
    }
    if (page === null || page === void 0 ? void 0 : page.meta_description) {
        seoTags.meta.push({
            hid: 'description',
            name: 'description',
            content: page.meta_description,
        });
    }
    if ((page === null || page === void 0 ? void 0 : page.meta_keyword) || (page === null || page === void 0 ? void 0 : page.meta_keywords)) {
        seoTags.meta.push({
            hid: 'keywords',
            name: 'keywords',
            content: (page === null || page === void 0 ? void 0 : page.meta_keyword) || (page === null || page === void 0 ? void 0 : page.meta_keywords),
        });
    }
    if (isNoIndex) {
        seoTags.meta.push({
            name: 'robots',
            content: 'noindex, nofollow',
        });
    }
    return seoTags;
};
