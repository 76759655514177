import findDeep from 'deepdash/findDeep';
/**
 * Traverse Category Tree to find and return active category by "findBy" param
 *
 * @param categoryTree CategoryTree
 * @param toFind string
 * @param findBy string (default = url_path)
 *
 * @return CategoryTree
 */
export function findActiveCategory(categoryTree, toFind, findBy = 'url_path') {
    var _a, _b;
    const categories = categoryTree === null || categoryTree === void 0 ? void 0 : categoryTree.children;
    return categories
        ? (_b = (_a = findDeep(categories, (value, key) => key === findBy && value === toFind)) === null || _a === void 0 ? void 0 : _a.parent) !== null && _b !== void 0 ? _b : null
        : null;
}
