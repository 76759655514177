import { useApi } from '~/composables/useApi';
import getPricesQuery from '~/modules/catalog/pricing/getPricesQuery.gql';
export const usePrice = () => {
    const getPrices = async (variables) => {
        var _a;
        const { query } = useApi();
        const { data } = await query(getPricesQuery, variables);
        return (_a = data === null || data === void 0 ? void 0 : data.products) !== null && _a !== void 0 ? _a : { items: [] };
    };
    const getPricesBySku = async (skus, pageSize = 20, currentPage = 1) => getPrices({ filter: { sku: { in: skus } }, pageSize, currentPage });
    return { getPricesBySku, getPrices };
};
