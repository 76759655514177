import { useApi } from '~/composables/useApi';
import displayModesEnum from '~/modules/catalog/category/enums/displayModesEnum';
import categoryContentQuery from './categoryContent.gql';
export const useCategoryContent = () => {
    const { query } = useApi();
    const getContentData = async (uid) => {
        var _a, _b, _c;
        const { data } = await query(categoryContentQuery, { filters: { category_uid: { eq: uid } } });
        const category = (_a = data === null || data === void 0 ? void 0 : data.categoryList[0]) !== null && _a !== void 0 ? _a : {};
        const cmsBlock = (_b = category === null || category === void 0 ? void 0 : category.cms_block) !== null && _b !== void 0 ? _b : { cmsBlock: { content: '' } };
        const displayMode = (_c = category === null || category === void 0 ? void 0 : category.display_mode) !== null && _c !== void 0 ? _c : displayModesEnum.PRODUCTS;
        const isShowCms = displayMode === displayModesEnum.PAGE || displayMode === displayModesEnum.PRODUCTS_AND_PAGE;
        const isShowProducts = displayMode === displayModesEnum.PRODUCTS || displayMode === displayModesEnum.PRODUCTS_AND_PAGE;
        return {
            cmsBlock,
            displayMode,
            isShowCms,
            isShowProducts,
        };
    };
    return {
        getContentData,
    };
};
export default useCategoryContent;
