import { perPageOptions } from '~/modules/catalog/category/composables/useFacet/perPageOptions';
const facetGetters = {
    getSortOptions(searchData) {
        if (!searchData || !searchData.data || !searchData.data.availableSortingOptions) {
            return {
                options: [],
                selected: '',
            };
        }
        return {
            options: searchData.data.availableSortingOptions,
            selected: searchData.input.sort,
        };
    },
    getProducts(searchData) {
        if (!searchData || !searchData.data || !searchData.data.items) {
            return [];
        }
        return searchData.data.items;
    },
    getPagination(searchData) {
        var _a, _b, _c, _d;
        const totalPages = (searchData === null || searchData === void 0 ? void 0 : searchData.data) ? (Number.isNaN(Math.ceil(searchData.data.total / searchData.input.itemsPerPage))
            ? 1
            : Math.ceil(searchData.data.total / searchData.input.itemsPerPage)) : 1;
        return {
            currentPage: (((_a = searchData === null || searchData === void 0 ? void 0 : searchData.input) === null || _a === void 0 ? void 0 : _a.page) > totalPages ? 1 : (_b = searchData === null || searchData === void 0 ? void 0 : searchData.input) === null || _b === void 0 ? void 0 : _b.page) || 1,
            totalPages,
            totalItems: ((_c = searchData === null || searchData === void 0 ? void 0 : searchData.data) === null || _c === void 0 ? void 0 : _c.total) ? searchData.data.total : 0,
            itemsPerPage: ((_d = searchData === null || searchData === void 0 ? void 0 : searchData.input) === null || _d === void 0 ? void 0 : _d.itemsPerPage) || 10,
            pageOptions: perPageOptions,
        };
    },
};
export default facetGetters;
