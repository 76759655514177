import LazyHydrate from 'vue-lazy-hydration';
import { SfSelect, SfHeading, } from '@storefront-ui/vue';
import { computed, defineComponent, onMounted, ref, ssrRef, useFetch, } from '@nuxtjs/composition-api';
import { CacheTagPrefix, useCache } from '@vue-storefront/cache';
import { usePageStore } from '~/stores/page';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import CategoryPagination from '~/modules/catalog/category/components/pagination/CategoryPagination.vue';
import { useCategory, useFacet, useUiHelpers, useUiState, } from '~/composables';
import { useAddToCart } from '~/helpers/cart/addToCart';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import { usePrice } from '~/modules/catalog/pricing/usePrice';
import { useCategoryContent } from '~/modules/catalog/category/components/cms/useCategoryContent';
import { useTraverseCategory } from '~/modules/catalog/category/helpers/useTraverseCategory';
import facetGetters from '~/modules/catalog/category/getters/facetGetters';
import { getMetaInfo } from '~/helpers/getMetaInfo';
import CategoryNavbar from '~/modules/catalog/category/components/navbar/CategoryNavbar.vue';
import CategoryBreadcrumbs from '~/modules/catalog/category/components/breadcrumbs/CategoryBreadcrumbs.vue';
export default defineComponent({
    name: 'CategoryPage',
    components: {
        CategoryPagination,
        CategoryEmptyResults: () => import('~/modules/catalog/category/components/CategoryEmptyResults.vue'),
        CategoryFilters: () => import('~/modules/catalog/category/components/filters/CategoryFilters.vue'),
        CmsContent: () => import('~/modules/catalog/category/components/cms/CmsContent.vue'),
        CategoryProductGrid: () => import('~/modules/catalog/category/components/views/CategoryProductGrid.vue'),
        CategoryProductList: () => import('~/modules/catalog/category/components/views/CategoryProductList.vue'),
        CategoryNavbar,
        CategoryBreadcrumbs,
        SfSelect,
        LazyHydrate,
        SfHeading,
        SkeletonLoader,
    },
    transition: 'fade',
    setup() {
        const { routeData } = usePageStore();
        const { getContentData } = useCategoryContent();
        const { loadCategoryMeta } = useCategory();
        const { addTags } = useCache();
        const uiHelpers = useUiHelpers();
        const cmsContent = ref('');
        const isShowCms = ref(false);
        const isShowProducts = ref(false);
        const products = ssrRef([]);
        const sortBy = ref({ selected: '', options: [] });
        const pagination = ref({});
        const productContainerElement = ref(null);
        const { toggleFilterSidebar, changeToCategoryListView, changeToCategoryGridView, isCategoryGridView, isFilterSidebarOpen, } = useUiState();
        const { load: loadWishlist, addItem: addItemToWishlistBase, isInWishlist, removeItem: removeItemFromWishlist, } = useWishlist();
        const { result, search } = useFacet();
        const { addItemToCart } = useAddToCart();
        const categoryMeta = ref(null);
        const addItemToWishlist = async (product) => {
            await (isInWishlist({ product })
                ? removeItemFromWishlist({ product })
                : addItemToWishlistBase({ product }));
        };
        const { activeCategory, loadCategoryTree } = useTraverseCategory();
        const activeCategoryName = computed(() => { var _a, _b; return (_b = (_a = activeCategory.value) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : ''; });
        const categoryUid = routeData.uid;
        const { fetch } = useFetch(async () => {
            var _a, _b, _c;
            if (!activeCategory.value) {
                await loadCategoryTree();
            }
            const [content, categoryMetaData] = await Promise.all([
                getContentData(categoryUid),
                loadCategoryMeta({ category_uid: categoryUid }),
                search({ ...uiHelpers.getFacetsFromURL(), category_uid: categoryUid }),
            ]);
            categoryMeta.value = categoryMetaData;
            cmsContent.value = (_b = (_a = content === null || content === void 0 ? void 0 : content.cmsBlock) === null || _a === void 0 ? void 0 : _a.content) !== null && _b !== void 0 ? _b : '';
            isShowCms.value = content.isShowCms;
            isShowProducts.value = content.isShowProducts;
            products.value = (_c = facetGetters.getProducts(result.value)) !== null && _c !== void 0 ? _c : [];
            sortBy.value = facetGetters.getSortOptions(result.value);
            pagination.value = facetGetters.getPagination(result.value);
            const tags = [{ prefix: CacheTagPrefix.View, value: routeData.uid }];
            const productTags = products.value.map((product) => ({
                prefix: CacheTagPrefix.Product,
                value: product.uid,
            }));
            addTags([...tags, ...productTags]);
        });
        const isPriceLoaded = ref(false);
        onMounted(async () => {
            loadWishlist();
            const { getPricesBySku } = usePrice();
            if (products.value.length > 0) {
                const skus = products.value.map((item) => item.sku);
                const priceData = await getPricesBySku(skus, pagination.value.itemsPerPage);
                products.value = products.value.map((product) => {
                    var _a;
                    const priceRange = (_a = priceData.items.find((item) => item.sku === product.sku)) === null || _a === void 0 ? void 0 : _a.price_range;
                    if (priceRange) {
                        return {
                            ...product,
                            price_range: priceRange,
                        };
                    }
                    return { ...product };
                });
            }
            isPriceLoaded.value = true;
        });
        const goToPage = (page) => {
            uiHelpers.changePage(page, false);
            fetch();
        };
        const doChangeItemsPerPage = (itemsPerPage) => {
            uiHelpers.changeItemsPerPage(itemsPerPage, false);
            goToPage(0);
        };
        const onReloadProducts = () => {
            goToPage(0);
            productContainerElement.value.scrollIntoView();
        };
        return {
            isPriceLoaded,
            ...uiHelpers,
            toggleFilterSidebar,
            isCategoryGridView,
            changeToCategoryListView,
            changeToCategoryGridView,
            isFilterSidebarOpen,
            addItemToCart,
            addItemToWishlist,
            pagination,
            products,
            sortBy,
            isShowCms,
            isShowProducts,
            cmsContent,
            activeCategoryName,
            routeData,
            doChangeItemsPerPage,
            productContainerElement,
            categoryMeta,
            onReloadProducts,
            goToPage,
        };
    },
    head() {
        return getMetaInfo(this.categoryMeta);
    },
});
